import React, { useCallback, useEffect, useState } from 'react';
import {
  Client,
  messageCallbackType,
  StompHeaders,
  StompSubscription,
} from '@stomp/stompjs';
import { useSelector } from '@/hooks/useSelector';
import { useAppDispatch } from '@/app/store';
import { updateInventoryDetails } from '@/features/inventory/inventorySlice';
import type { NotificationInfo } from '@/api/receive';

export enum ClientStatus {
  CONNECTED = 'CONNECTED',
  DISCONNECTED = 'DISCONNECTED',
}

interface ISocketContext {
  stompClient: Client;
  setStompClient: React.Dispatch<React.SetStateAction<Client>>;
  clientStatus: ClientStatus;
  setClientStatus: React.Dispatch<React.SetStateAction<ClientStatus>>;
  virtualBuddyNotificationIsVisible: boolean;
  setVirtualBuddyNotificationVisibility: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  connect: () => Promise<void>;
  disconnect: () => Promise<void>;
}

const SocketContext = React.createContext<ISocketContext>({
  stompClient: new Client(),
  setStompClient: () => {},

  clientStatus: ClientStatus.DISCONNECTED,
  setClientStatus: () => {},

  virtualBuddyNotificationIsVisible: false,
  setVirtualBuddyNotificationVisibility: () => {},

  connect: async () => {},
  disconnect: async () => {},
});

export const SocketContextProvider: React.FC = ({ children }) => {
  const dispatch = useAppDispatch();
  const { username } = useSelector(state => state.user);
  const { inventoryId } = useSelector(
    state => state.inventory.inventoryDetails
  );

  const [
    virtualBuddyNotificationIsVisible,
    setVirtualBuddyNotificationVisibility,
  ] = useState<boolean>(false);

  const [clientStatus, setClientStatus] = useState<ClientStatus>(
    ClientStatus.DISCONNECTED
  );

  const [stompClient, setStompClient] = useState<Client>(
    new Client({
      brokerURL: process.env.REACT_APP_WSS_ADDRESS,
    })
  );

  const connect = useCallback(async () => {
    await stompClient.activate();
    setClientStatus(ClientStatus.CONNECTED);
    console.log(`📱 🔥 ~ Connected to the socket ~ `);
  }, [stompClient]);

  const disconnect = useCallback(async () => {
    await stompClient.forceDisconnect();
    setClientStatus(ClientStatus.DISCONNECTED);
    console.log(`📱 🔥 ~ Disconnected from the socket ~ `);
  }, [stompClient]);

  const subscribe = useCallback(
    (topic: string, callback: messageCallbackType, headers?: StompHeaders) =>
      stompClient.subscribe(topic, callback, headers),
    [stompClient]
  );

  const subscribeInventory = useCallback(
    (topic: string, headers?: StompHeaders): StompSubscription => {
      const callback: messageCallbackType = ({ body }) => {
        console.log(
          `📱 🔥 ~ Data received from the topic ${topic} with data: ${JSON.parse(
            body
          )} ~`
        );

        dispatch(updateInventoryDetails(body));
      };

      return subscribe(topic, callback, headers);
    },
    [dispatch, subscribe]
  );

  const subscribeNotification = useCallback(
    (topic: string, headers?: StompHeaders): StompSubscription => {
      const callback: messageCallbackType = ({ body }) => {
        console.log(
          `📱 🔥 ~ Data received from the topic ${topic} with data: ${JSON.parse(
            body
          )} ~`
        );

        const notification = JSON.parse(body) as NotificationInfo;

        if (notification.typeProcess === 'INVT') {
          setVirtualBuddyNotificationVisibility(true);
        }
      };

      return subscribe(topic, callback, headers);
    },
    [subscribe]
  );

  useEffect(() => {
    stompClient.onConnect = (): void => {
      if (inventoryId) {
        console.log(
          `📱 🔥 ~ Subscribed to the topic /inventory/${inventoryId} ~`
        );
        console.log(
          `📱 🔥 ~ Subscribed to the topic /notification/${username} ~`
        );
      }

      subscribeInventory(`/inventory/${inventoryId}`);
      subscribeNotification(`/notification/${username}`);
    };
  }, [
    inventoryId,
    stompClient,
    subscribeInventory,
    subscribeNotification,
    username,
  ]);

  useEffect(() => {
    // Will be invoked in case of error encountered at Broker
    stompClient.onStompError = (frame): void => {
      console.log('Broker reported error: ' + frame.headers['message']);
      console.log('Additional details: ' + frame.body);
    };
  }, [stompClient]);

  return (
    <SocketContext.Provider
      value={{
        connect,
        disconnect,
        stompClient,
        setStompClient,
        clientStatus,
        setClientStatus,
        virtualBuddyNotificationIsVisible,
        setVirtualBuddyNotificationVisibility,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export const useSocketContext = (): ISocketContext =>
  React.useContext(SocketContext);
