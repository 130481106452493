import React, { useEffect, useState } from 'react';
import { useSelector } from '@/hooks/useSelector';
import { useAppDispatch } from '@/app/store';
import { useAsync } from 'react-use';
import { useHistory, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useSocketContext } from '@/context/socketContext';
import { DateTime } from 'luxon';

import { UIBox } from '@/components/ui/Box';
import { UIButtonWithIcon } from '@/components/ui/Button';
import { InventoryItemScan } from '@/components/layout/InventoryItem';
import { Typography } from '@/components/ui/Typography';
import { ErrorSnackbar } from '@/components/ui/ErrorSnackbar';
import { ModalAttention } from '@/components/layout/ModalAttention';
import { CTAContainer } from '@/components/layout/CTAContainer';
import { PageLoader } from '@/components/ui/PageLoader';
import PersonIcon from '@material-ui/icons/Person';
import RefreshIcon from '@material-ui/icons/Refresh';

import {
  addInventoryEpc,
  fetchInventoryDetails,
  fetchInventoryReport,
  setInventoryOwner,
} from '@/features/inventory/inventorySlice';
// import { update as BCupdate } from '@/features/breadcrumbs/breadcrumbsSlice';
import { colors } from '@/theme';
import { isInventoryOwner } from '@/utils/user';
import { InventoryService } from '@/api/receive';
import { AppRoutes } from '@/app/routers';
import { ScanDevicesService } from '@/api';
import useResetDevice from '@/hooks/useResetDevice';
import { initMQTTTags, tagsMQTTDevice } from '@/features/devices/devicesSlice';
import { useSubscribeEpcs } from '@/hooks/mqtt/useSubscribeEpcs';

type Params = { id: string };

const PageInventoryScan: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { id } = useParams<Params>();
  const { connect, disconnect } = useSocketContext();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const user = useSelector(state => state.user);
  const storeCode = useSelector(state => state.currentStore.store?.storeCode);
  const { deviceInUse, tags } = useSelector(state => state.devices);
  const { resetDevice } = useResetDevice();
  const { inventoryOwner, fetchInventoryHasError, fetchInventoryIsLoading } =
    useSelector(state => state.inventory);
  const {
    inventoryStartTime,
    brands,
    stopInventory,
    finishAndSeeResults,
    users,
  } = useSelector(state => state.inventory.inventoryDetails);
  const [isScanning, setIsScanning] = useState<boolean>(true);
  const [deviceError, setDeviceError] = useState<boolean>(false);
  const [deviceErrorMsg, setDeviceErrorMsg] = useState<string>();
  const [inventoryError, setInventoryError] = useState<boolean>(false);
  const [quitError, setQuitError] = useState<boolean>(false);
  const [finishError, setFinishError] = useState<boolean>(false);
  const [seeResultsError, setSeeResultsError] = useState<boolean>(false);
  const [quitLoading, setQuitLoading] = useState<boolean>(false);
  const [seeResultsLoading, setSeeResultsLoading] = useState<boolean>(false);
  const [finishAndExitLoading, setFinishAndExitLoading] =
    useState<boolean>(false);
  const [finishModalIsVisible, setFinishModalVisibility] =
    useState<boolean>(false);
  const [quitModalIsVisibile, setQuitModalVisibility] =
    useState<boolean>(false);

  const subscribeEPCS = useSubscribeEpcs(deviceInUse?.deviceId!);
  const foundBrands = brands ? brands.filter(({ found }) => found! > 0) : [];

  const addEpcs = React.useCallback(
    async (epcs: string[]) => {
      try {
        await dispatch(
          addInventoryEpc({
            isInventoryOwner: false,
            inventoryId: id,
            epcs,
          })
        ).unwrap();
      } catch (e) {
        console.error(e);
      }
    },
    [dispatch, id]
  );

  useAsync(async () => {
    if (storeCode) {
      await dispatch(
        fetchInventoryDetails({
          inventoryId: id,
          storeCode,
        })
      );

      await connect();
      subscribeEPCS.subscribe();

      dispatch(setInventoryOwner());
    }
  }, []);

  useEffect(() => {
    if (fetchInventoryHasError) {
      setInventoryError(true);
    }
  }, [fetchInventoryHasError]);

  useEffect(() => {
    if (stopInventory) {
      history.push(AppRoutes.INVENTORY);
    }
  }, [history, stopInventory]);

  useAsync(async () => {
    if (tags && tags.length > 0) {
      console.log(`Inventory ID: ${id}. Scanned epcs: ${tags}`);
      await addEpcs(tags);
    }
  }, [tags.length]);

  // BOTH USER ACTIONS
  const scanHandler = async (): Promise<void> => {
    setIsScanning(prev => !prev);

    const { code, message } = await ScanDevicesService.activateScanning({
      requestBody: {
        dev_id: deviceInUse?.deviceId!,
        enable: isScanning ? 'False' : 'True',
      },
    });

    if (code !== 'OK') {
      setDeviceErrorMsg(message);
      setIsScanning(false);
      return setDeviceError(true);
    }
  };

  const updateScanHandler = async (): Promise<string[]> => {
    await disconnect();
    await connect();

    const { tags: deviceTags } = await ScanDevicesService.getTags({
      devId: deviceInUse?.deviceId!,
    });

    if (deviceTags && deviceTags.length > 0) {
      dispatch(tagsMQTTDevice(deviceTags));
    }

    return deviceTags?.map(({ epc }) => epc!) || [];
  };
  // ------------------------------------------------

  // OPERATOR ACTIONS
  const finishAndExit = async (): Promise<void> => {
    if (storeCode) {
      try {
        setFinishAndExitLoading(true);

        const tags = await updateScanHandler();

        if (tags.length > 0) {
          await addEpcs(tags);
        }

        await InventoryService.inventoryFinishAndExit({
          requestBody: {
            inventoryId: id,
            storeCode,
          },
        });

        await disconnect();
        await resetDevice();

        setFinishAndExitLoading(false);

        history.push(AppRoutes.INVENTORY);
      } catch {
        setFinishAndExitLoading(false);
        setFinishError(true);
      }
    }
  };
  // ------------------------------------------------

  // OWNER ACTIONS
  const seeResultsHandler = async (): Promise<void> => {
    if (storeCode) {
      try {
        setSeeResultsLoading(true);

        const tags = await updateScanHandler();

        if (tags.length > 0) {
          await addEpcs(tags);
        }

        await dispatch(fetchInventoryReport(id)).unwrap();

        await InventoryService.inventoryChangeInventoryStatus({
          requestBody: {
            idInventory: id,
            status: 'Waiting for Application',
            isRetry: false,
            storeCode,
          },
        });

        await disconnect();
        await resetDevice();

        /**
         * The tags array is sent to BE in the inventory report page aswell
         * Subsequently its cleared to avoid sending dirty data
         */
        dispatch(initMQTTTags());

        setSeeResultsLoading(false);
        history.push(`${AppRoutes.INVENTORY}-report/${id}`);
      } catch {
        setSeeResultsLoading(false);
        setSeeResultsError(true);
      }
    }
  };

  const quitHandler = async (): Promise<void> => {
    if (storeCode) {
      try {
        setQuitLoading(true);

        await InventoryService.inventoryQuitInventory({
          requestBody: {
            inventoryId: id,
            storeCode,
          },
        });

        await disconnect();
        await resetDevice();

        setQuitLoading(false);

        history.push(AppRoutes.INVENTORY);
      } catch {
        setQuitLoading(false);
        setQuitError(true);
      }
    }
  };
  // ------------------------------------------------

  const inventoryDate = DateTime.fromSeconds(
    Number(inventoryStartTime) || 0
  ).toFormat('yyyy LLLL dd, hh:mm a', {
    locale: i18n.language,
  });

  const totalScannedQuantity =
    brands && brands.reduce((a, b) => a + b.scannedTags!, 0);

  const disableConfirm =
    (!finishAndSeeResults && users?.length! > 1) || foundBrands.length === 0;

  if (fetchInventoryIsLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <ModalAttention
        open={quitModalIsVisibile}
        onConfirmClick={quitHandler}
        loadingConfirm={quitLoading}
        disableConfirm={quitLoading}
        onClose={(): void => setQuitModalVisibility(false)}
        messageMaxWidth="75%"
        message={t('quitInventoryMessage')}
      />
      <ModalAttention
        open={finishModalIsVisible}
        onConfirmClick={finishAndExit}
        loadingConfirm={finishAndExitLoading}
        disableConfirm={finishAndExitLoading}
        onClose={(): void => setFinishModalVisibility(false)}
        messageMaxWidth="75%"
        message={t('finishAndExitMessage')}
      />
      <UIBox width="100%" flexDirection="column" mb="100px" padding={3}>
        <UIBox width="100%" alignItems="center" justifyContent="space-between">
          <UIBox alignItems="center">
            <PersonIcon fontSize="large" htmlColor={colors.grey} />
            <UIBox ml={2} flexDirection="column">
              <Typography margin="4px 0" color="grey">
                {inventoryOwner}
              </Typography>
              <Typography font="heavy">{t('inProgress')}</Typography>
            </UIBox>
          </UIBox>
          <Typography size="lg" font="heavy">
            {t('inventory')}: {inventoryDate}
          </Typography>
          <UIBox alignItems="center">
            <Typography color="grey">{t('totalScannedQty')}:</Typography>
            <Typography margin="0 0 0 8px" size="xl" font="heavy">
              {totalScannedQuantity}
            </Typography>
          </UIBox>
        </UIBox>
        <UIBox mt={2} mb={3} width="100%">
          <UIBox ml="auto">
            <UIButtonWithIcon
              label={t('refresh')}
              startIcon={<RefreshIcon />}
              onClick={updateScanHandler}
            />
          </UIBox>
        </UIBox>
        {brands && brands.length > 0 ? (
          <>
            <UIBox pl={2} pr={2} justifyContent="space-between">
              <Typography size="lg" font="heavy">
                {t('brands')}
              </Typography>
              <Typography size="lg" font="heavy">
                {t('scannedTags')}
              </Typography>
            </UIBox>
            <UIBox width="100%" flexDirection="column">
              {foundBrands.map((brand, index) => (
                <InventoryItemScan
                  {...brand}
                  key={`${brand.brandName}-${index}`}
                />
              ))}
            </UIBox>
          </>
        ) : (
          <UIBox
            width="100%"
            mt={10}
            mb={10}
            alignItems="center"
            justifyContent="center"
          >
            <Typography font="heavy" color="grey">
              {t('noScannedTagsYet')}
            </Typography>
          </UIBox>
        )}
      </UIBox>
      {isInventoryOwner(user) && user.username === inventoryOwner ? (
        <CTAContainer
          type="THREE_BUTTONS"
          secondaryButtonOutlined
          backButtonLabel={t('quit')}
          disabledConfirm={disableConfirm}
          secondaryButtonLabel={isScanning ? t('pauseScan') : t('scanagain')}
          mainButtonLabel={t('finishAndSeeResults')}
          onQuitClick={(): void => setQuitModalVisibility(true)}
          onScanClick={scanHandler}
          loadingConfirm={seeResultsLoading}
          onConfirmClick={seeResultsHandler}
        />
      ) : (
        <CTAContainer
          type="THREE_BUTTONS"
          hideBack
          secondaryButtonOutlined
          secondaryButtonLabel={isScanning ? t('pauseScan') : t('scanagain')}
          mainButtonLabel={t('finishAndExit')}
          onQuitClick={(): void => {}}
          onScanClick={scanHandler}
          onConfirmClick={(): void => setFinishModalVisibility(true)}
        />
      )}
      <ErrorSnackbar
        open={inventoryError}
        setIsOpen={setInventoryError}
        errorMessage={t('error.inventory_details_loading')}
      />
      <ErrorSnackbar
        open={quitError}
        setIsOpen={setQuitError}
        errorMessage={t('error.inventory_quit')}
      />
      <ErrorSnackbar
        open={finishError}
        setIsOpen={setFinishError}
        errorMessage={t('error.finish_inventory')}
      />
      <ErrorSnackbar
        open={seeResultsError}
        setIsOpen={setSeeResultsError}
        errorMessage={t('error.see_results_inventory')}
      />
      <ErrorSnackbar
        open={deviceError}
        setIsOpen={setDeviceError}
        errorMessage={deviceErrorMsg}
      />
    </>
  );
};

export default PageInventoryScan;
