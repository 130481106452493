import { FC, useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Row } from '@/components/layout/Row';
import { UIBox } from '@/components/ui/Box';
import { Typography } from '@/components/ui/Typography';

import { palette, TColorName } from '@/theme';

import { DeviceStatus } from '@/types/enum';
import { useSelector } from '@/hooks/useSelector';
import { useSubscribeDeviceStatus } from '@/hooks/mqtt/useSubscribeDeviceStatus';

export interface DeviceInfoProps {
  deviceId?: string;
  deviceName?: string;
  status?: string;
  battery?: number;
  userName?: string;
  easteregg?: boolean;
  $v2?: boolean;
}

//#region - Styled Components
const statusColorsMap: { [index: string]: string } = {
  online: 'green',
  offline: 'grey',
  busy: 'yellow',
};

const StyledDeviceInfo = styled(UIBox)<Partial<DeviceInfoProps>>`
  && {
    width: ${({ $v2 }): string => ($v2 ? '100%' : '186px')};
    height: 36px;
    display: inline-flex;
    border-color: ${({ theme, status }): TColorName =>
      status && theme.palette.colors[statusColorsMap[status]]};
    border-width: ${({ $v2 }): string => ($v2 ? '0px' : '2px')};
    border-style: solid;
    border-radius: 6px;
    position: relative;
    padding: 2px 6px 2px 12px;
    align-items: center;
    gap: ${({ $v2 }): string => ($v2 ? '5px' : '0px')};
    justify-content: ${({ $v2 }): string =>
      $v2 ? 'flex-start' : 'space-between'};

    & > * {
      color: ${({ $v2 }): string =>
        $v2 ? `${palette.colors.text}` : `${palette.colors.text}`};
    }
  }
`;

const StyledBorder = styled('div')<Partial<DeviceInfoProps>>`
  width: 7px;
  height: 34px;
  background-color: ${({ theme, status }): TColorName =>
    status && theme.palette.colors[statusColorsMap[status]]};
  border-radius: 6px;
  position: absolute;
  top: -1px;
  left: 0;
`;

const StyledBatteryIcon = styled('div')`
  width: 11px;
  height: 20px;
  background-color: ${palette.colors.grey};
  border-radius: 2px;
  position: relative;
  margin-left: 5px;

  &:after {
    content: ' ';
    background: ${palette.colors.grey};
    width: 5px;
    height: 3px;
    display: block;
    position: absolute;
    top: -3px;
    left: 3px;
  }
`;

const StyledBatteryIconValue = styled('div')<Partial<DeviceInfoProps>>`
  width: 11px;
  height: ${({ battery }): number =>
    battery ? Math.ceil((20 / 100) * battery) : 0}px;
  background-color: ${({ status, $v2 }): string =>
    $v2 ? statusColorsMap[status!] : `${palette.colors.text}`};
  border-radius: 2px;
  position: absolute;
  bottom: ${({ $v2 }): string => ($v2 ? '0' : 'initial')};

  &:after {
    content: ' ';
    display: ${({ battery }): string =>
      battery && battery >= 98 ? 'block' : 'none'};
    background: ${palette.colors.text};
    width: 5px;
    height: 3px;
    position: absolute;
    top: -3px;
    left: 3px;
  }
`;

const StyledBatteryContainer = styled(UIBox)`
  padding-bottom: 2px;
`;

const StyledDeviceStatus = styled(Typography)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 120px;
`;

const TitleWrapper = styled(Typography)`
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
//#endregion

export const DeviceInfo: FC<DeviceInfoProps> = ({
  battery,
  deviceName,
  status,
  userName,
  deviceId,
  easteregg,
  $v2 = false,
  ...rest
}) => {
  const { t } = useTranslation();
  const deviceInfo = useRef<HTMLDivElement>(null);

  const { username } = useSelector(state => state.user);

  const subscribeDevice = useSubscribeDeviceStatus();

  const resetDevice = useCallback(async (): Promise<void> => {
    if (deviceId && userName === username) {
      subscribeDevice.publish(deviceId!, undefined);
    }
  }, [deviceId, userName, username, subscribeDevice]);

  /** useRef used for count **/
  const countRef = useRef(0);
  /** useRef used for timer id **/
  const timerRef = useRef();

  const handleTripleClick = (): void => {
    countRef.current = countRef.current + 1;
    const timerIsPresent = timerRef.current;
    if (
      easteregg &&
      timerIsPresent &&
      countRef.current === 3 &&
      status === DeviceStatus.BUSY
    ) {
      clearTimeout(timerRef.current);
      timerRef.current = undefined;
      countRef.current = 0;
      if (window.confirm(`Reset device ${deviceName} (${deviceId})?`)) {
        resetDevice();
      }
    }
    if (!timerIsPresent) {
      const timer = setTimeout(() => {
        clearTimeout(timerRef.current);
        timerRef.current = undefined;
        countRef.current = 0;
      }, 1000);
      // @ts-ignore;
      timerRef.current = timer;
    }
  };

  useEffect(() => {
    const device = deviceInfo.current;
    if (device) {
      device.addEventListener('click', handleTripleClick);

      // cleanup this component
      return (): void => {
        device.removeEventListener('click', handleTripleClick);
      };
    }
  });

  if ($v2) {
    return (
      <Row mt="0">
        <div ref={deviceInfo}>
          <StyledDeviceInfo
            justifyContent="space-between"
            status={status}
            $v2={$v2}
            {...rest}
          >
            <TitleWrapper font="heavy" size="sm" color="disabled">
              {deviceName}
            </TitleWrapper>
            <Typography font="heavy" size="sm" color="disabled">
              -
            </Typography>
            <StyledDeviceStatus size="sm" color="disabled">
              {status === DeviceStatus.BUSY ? t(status, { userName }) : status}
            </StyledDeviceStatus>
            <StyledBatteryIcon>
              <StyledBatteryIconValue
                battery={battery}
                status={status}
                $v2={$v2}
              />
            </StyledBatteryIcon>
          </StyledDeviceInfo>
        </div>
      </Row>
    );
  }

  return (
    <Row mt="0">
      <div ref={deviceInfo}>
        <StyledDeviceInfo
          justifyContent="space-between"
          status={status}
          {...rest}
        >
          <StyledBorder status={status} />
          <UIBox flexDirection="column" justifyContent="space-evenly">
            <TitleWrapper font="heavy" size="sm">
              {deviceName}
            </TitleWrapper>
            <StyledDeviceStatus size="xs" color="disabled">
              {status === DeviceStatus.BUSY ? t(status, { userName }) : status}
            </StyledDeviceStatus>
          </UIBox>

          {status !== DeviceStatus.OFFLINE && (
            <StyledBatteryContainer
              flexDirection="row"
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <StyledBatteryIcon />
              <StyledBatteryIconValue battery={battery} />
            </StyledBatteryContainer>
          )}
        </StyledDeviceInfo>
      </div>
    </Row>
  );
};

DeviceInfo.displayName = 'DeviceInfo';

export default DeviceInfo;
